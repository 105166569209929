import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.js";
import "./assets/scss/themes.scss";
import "./App.css";
import "react-phone-number-input/style.css";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import create from "zustand";
import { persist, devtools } from "zustand/middleware";
import {
  AdminRoute,
  CandidateRoute,
  CompanyRoute,
  TrainerRoute,
} from "./components/common/admin-route.component";
import useAxios from "./api/useAxios";
import { useQuery } from "react-query";
import { useState, useEffect, lazy, Suspense } from "react";
import ScrollToTop from "./components/common/scroll-to-top.component";

// Public View Imports
import ReactGA from "react-ga4";



// Admin Dashboard Imports
const AdminLayout = lazy(() => import("./layout/admin-dash"));
const AdminSignIn = lazy(() => import("./pages/admin-dash/signin-page"));
const AdminDashPage = lazy(() => import("./pages/admin-dash/dashboard-page"));
const AdminActiveCompanies = lazy(() => import("./pages/admin-dash/companies/active-companies-page"));
const SignUpRequests = lazy(() => import("./pages/admin-dash/companies/signup-requests-page"));
const SuspendedCompanies = lazy(() => import("./pages/admin-dash/companies/suspended-companies-page"));
const AdminListOfSkills = lazy(() => import("./pages/admin-dash/skills/skills-list-page"));
const AddNewSkill = lazy(() => import("./pages/admin-dash/skills/add-new-skills-page"));
const ApproveSkills = lazy(() => import("./pages/admin-dash/skills/approve-skills-page"));
const CounsellingRequestsPage = lazy(() => import("./pages/admin-dash/counselling/counselling-requests-page"));
const CounsellorsPage = lazy(() => import("./pages/admin-dash/counselling/counsellors-page"));
const AddNewTrainingPage = lazy(() => import("./pages/admin-dash/trainings/add-new-training-page"));
const ListOfTrainingsPage = lazy(() => import("./pages/admin-dash/trainings/ViewTrainings/list-of-trainings-page"));
const TrainingPage = lazy(() => import("./pages/admin-dash/trainings/update-training-page"));
const TrainingsRequestsPage = lazy(() => import("./pages/admin-dash/trainings/trainings-requests-page"));
const TrainingAdminDetail = lazy(() => import("./pages/admin-dash/trainings/training-detail-page"));
const UpdateTrainingPage = lazy(() => import("./pages/admin-dash/trainings/update-training-page"));

// Candidate Dashboard Imports
const CandidateLayout = lazy(() => import("./layout/candidate-dash"));
const CandidateDashPage = lazy(() => import("./pages/candidate-dash/dashboard-page"));
const MyJobs = lazy(() => import("./pages/candidate-dash/my-jobs.page"));
const ViewDownloadCV = lazy(() => import("./pages/candidate-dash/view-download-cv"));
const JobPreferences = lazy(() => import("./pages/candidate-dash/job-preferences-page"));
const CandidateProfile = lazy(() => import("./pages/candidate-dash/profile-page"));
const CampusAmbassadorProfile = lazy(() => import("./pages/candidate-dash/campus-ambassador-profile"));
const Chat = lazy(() => import("./pages/company-dash/chat/chat-page"));

// Company Dashboard Imports
const CompanyLayout = lazy(() => import("./layout/company-dash")) ;
const CompanyDashPage = lazy(() => import("./pages/company-dash/dashboard-page")) ;
const SearchResourcesPage = lazy(() => import("./pages/company-dash/outsourcing/search-resources-page")) ;
const ViewResources = lazy(() => import("./pages/company-dash/outsourcing/view-resources-page")) ;
const ResourceDetailPage = lazy(() => import("./pages/company-dash/outsourcing/resource-detail-page")) ;
const AddNewResource = lazy(() => import("./pages/company-dash/outsourcing/add-new-resource-page")) ;
const ListOfSkills = lazy(() => import("./pages/company-dash/outsourcing/skills-list-page")) ;
const RequestNewSkill = lazy(() => import("./pages/company-dash/outsourcing/request-new-skill-page")) ;
const CompanyProfile = lazy(() => import("./pages/company-dash/company-profile-page")) ;
const ListOfCompanies = lazy(() => import("./pages/company-dash/outsourcing/companies-list-page")) ;
const OutsourcingRequests = lazy(() => import("./pages/company-dash/outsourcing/outsourcing-requests-page")) ;
const PostJob = lazy(() => import("./pages/company-dash/jobs/post-job-page")) ;
const ViewJobs = lazy(() => import("./pages/company-dash/jobs/viewJobs/view-jobs-page")) ;
const JobDetail = lazy(() => import("./pages/company-dash/jobs/job-detail-page")) ;
const ShortlistedCandidates = lazy(() => import("./pages/company-dash/jobs/shortlisted-candidates-page")) ;
const ShortlistedCandidateProfile = lazy(() => import("./pages/company-dash/jobs/shortlisted-candidate-profile")) ;
const CompanyDetail = lazy(() => import("./pages/company-dash/company-detail-page")) ;
const TermsAndConditions = lazy(() => import("./pages/public-view/terms-and-conditions/terms-and-condition.page")) ;
const AdminShortlistedCandidateProfile = lazy(() => import("./pages/admin-dash/jobs/shortlisted-candidate-profile")) ;
const AdminShortlistedCandidates = lazy(() => import("./pages/admin-dash/jobs/shortlisted-candidates-page")) ;
const CareerDetail = lazy(() => import("./pages/public-view/careers/careers-detail.component")) ;

//Trainer Dashboard Imports
const TrainersPage = lazy(() => import("./pages/admin-dash/trainers/trainers-page"));
const TrainerLayout = lazy(() => import("./layout/trainer-dash"));
const TrainerDashPage = lazy(() => import("./pages/trainer-dash/dashboard-page"));
const AddTraining = lazy(() => import("./pages/trainer-dash/trainings/add-training-page"));
const ViewTrainings = lazy(() => import("./pages/trainer-dash/trainings/ViewTrainings/view-trainings-page"));
const TrainingDetail = lazy(() => import("./pages/trainer-dash/trainings/training-detail-page"));
const AddSchedule = lazy(() => import("./pages/trainer-dash/schedules/add-schedule-page"));
const ViewSchedules = lazy(() => import("./pages/trainer-dash/schedules/ViewSchedules/view-schedules-page"));
const ScheduleDetail = lazy(() => import("./pages/trainer-dash/schedules/schedule-details-page"));
const TrainerProfile = lazy(() => import("./pages/trainer-dash/trainer-profile-page"));
const EditTraining = lazy(() => import("./pages/trainer-dash/trainings/edit-training-page"));


const ViewEnrollments = lazy(() => import("./pages/trainer-dash/schedules/view-enrollments-page"));
const EditSchedule = lazy(() => import("./pages/trainer-dash/schedules/edit-schedule-page"));
const AddAdminSchedule = lazy(() => import("./pages/admin-dash/schedules/add-schedule-page"));
const ViewAdminSchedules = lazy(() => import("./pages/admin-dash/schedules/ViewSchedules/view-schedules-page"));
const ViewAdminEnrollments = lazy(() => import("./pages/admin-dash/schedules/view-enrollments-page"));
const EditAdminSchedule = lazy(() => import("./pages/admin-dash/schedules/edit-schedule-page"));
const ScheduleDetailAdmin = lazy(() => import("./pages/admin-dash/schedules/schedule-details-page"));
const TrainerDetailsPage = lazy(() => import("./pages/admin-dash/trainers/trainer-details-page"));
const TrainerTrainingsPage = lazy(() => import("./pages/admin-dash/trainers/ViewTrainings/trainer-trainings-page"));
const TrainerSchedulesPage = lazy(() => import("./pages/admin-dash/trainers/ViewSchedules/trainer-schedules-page"));
const TrainingDetailsPage = lazy(() => import("./pages/public-view/trainings/training-details-page"));
const ScheduleDetailsPage = lazy(() => import("./pages/public-view/trainings/schedule-details-page"));
const MyTrainings = lazy(() => import("./pages/candidate-dash/ViewEnrollments/my-enrollments-page"));
const ViewRequests = lazy(() => import("./pages/trainer-dash/trainings/requests-list-page"));
const ViewTrainingRequests = lazy(() => import("./pages/admin-dash/trainings/requests-list-page"));
const ProfileCompletion = lazy(() => import("./pages/candidate-dash/auth/profile-completion"));
const ViewCandidatesPage = lazy(() => import("./pages/admin-dash/candidates/ViewCandidates/list-of-candidates-page"));
const CandidateDetailsPage = lazy(() => import("./pages/admin-dash/candidates/candidate-details-page"));
const CompanyJobsView = lazy(() => import("./pages/admin-dash/companies/viewJobs/view-jobs-page"));



const CompanyDetailsPage = lazy(() => import("./pages/candidate-dash/companyDetails/company-details-page"));
const ViewFavorites = lazy(() => import("./pages/candidate-dash/favorites/favorites-page"));
const NotificationsPage = lazy(() => import("./pages/company-dash/notifications/notifications-page"));


// LAZY LOADED COMPONENT
// Admin Dash
const { Spinner } = require("reactstrap/esm");

const CampusAmbassadorsAdminPage = lazy(() => import('./pages/admin-dash/campus-ambassadors/campus-ambassadors.page'));
const NotFoundPage = lazy(() => import("./pages/notFound.page"))


// public view pages
const PublicLayout = lazy(() => import("./layout/public-view")) ;
const LandingPage = lazy(() => import("./pages/public-view/landing/landing.page")) ;
const PricingPage = lazy(() => import("./pages/public-view/pricing/pricing.page")) ;
const AboutUsPage = lazy(() => import("./pages/public-view/about-us/about-us.page")) ;
const SignUpPage = lazy(() => import("./pages/public-view/authentication/sign-up.page"))
const SignInPage = lazy(() => import("./pages/public-view/authentication/sign-in.page"));
const Candidates = lazy(() => import("./pages/public-view/candidates"));
const CandidateDetailPage = lazy(() => import("./pages/public-view/candidates/candidate-detail-page"));

const JobsListingPage =lazy(() => import("./pages/public-view/jobs/jobs.page"));
const JobDetailPage =lazy(() => import("./pages/public-view/jobs/job-detail.page"));
const HowItWorks  = lazy(() => import("./pages/public-view/how-it-works-page"));
const TrainingsPage  = lazy(() => import("./pages/public-view/trainings/trainings.page"));
const CounsellingPage  = lazy(() => import("./pages/public-view/counselling/counselling.page"));
const ForgotPasswordPage  = lazy(() => import("./pages/public-view/password-reset/forgot-password.page"));
const ResetPasswordPage  = lazy(() => import("./pages/public-view/password-reset/reset-password.page"));
const CareerPage  = lazy(() => import("./pages/public-view/careers/career.page"));
const ResourceOutSourcing  = lazy(() => import("./pages/public-view/resource-outsourcing/resource-outsourcing.page"));
const InvestorsClub  = lazy(() => import("./pages/public-view/investors-club"));
const CampusAmbassadors  = lazy(() => import("./pages/public-view/campus-ambassadors"));
const ContactUsPage  = lazy(() => import("./pages/public-view/contact-us/contact-us.page"));
const SuperiorUniversityCandidates = lazy(() => import("./pages/public-view/candidates/superior-university-candidates-page"));


ReactGA.initialize("G-WVRK6VDMGM");

export const useStore = create(
  devtools(
    persist(
      (set) => ({
        userStatus: false,
        accessToken: null,
        refreshToken: null,
        userType: null,
        name: null,
        phone: null,
        email: null,
        profile_complete: null,
      }),
      {
        name: "auth-storage",
        getStorage: () => localStorage,
      }
    )
  )
);

function App() {
  let api = useAxios();

  const userType = useStore((state) => state.userType);
  const userStatus = useStore((state) => state.userStatus);

  const { data: unreadMessagesData, isSuccess: isUnreadMessagesSuccess } =
    useQuery(
      "unreadMessages",
      async () => {
        if (userType === "candidate" || userType === "company") {
          return api.get("chat/unread-messages");
        }
        return null;
      },
      {
        enabled: userType === "candidate" || userType === "company",
      }
    );

  const [unreadMessages, setUnreadMessages] = useState();

  useEffect(() => {
    if (unreadMessagesData?.data) {
      setUnreadMessages(unreadMessagesData?.data?.unread_chats);
    }
  }, [unreadMessagesData]);

  const userCategory = useStore((state) => state.userType);

  return (
    <Router>
      <div className="App">
        <ScrollToTop>
          <Suspense fallback={<div style={{'height': '100vh', 'display': 'flex', 'alignItems': 'center', 'justifyContent': 'center'}}>
            <Spinner type="grow" color="dark"/>
            
            </div>}>
          <Routes>
            {/* =========== Public View Routes =========== */}

            <Route path="*" element={<NotFoundPage />} />

            <Route element={<PublicLayout />}>
              <Route path="/" element={<LandingPage />} />
              <Route path="/pricing" element={<PricingPage />} />
              <Route path="/about-us" element={<AboutUsPage />} />
              <Route path="/sign-in" element={<SignInPage />} />
              <Route path="/sign-up" element={<SignUpPage />} />
              <Route path="/jobs" element={<JobsListingPage />} />

              <Route path="/jobs/:jobID" element={<JobDetailPage />} />
              <Route path="/job-seekers" element={<Candidates />} />
              <Route path="/job-seekers/superior-university" element={<SuperiorUniversityCandidates />} />
              <Route path="/job-seeker/:candidateSlug" element={<CandidateDetailPage />}/>

              <Route path="/careers/:jobID" element={<CareerDetail />} />

              <Route path="/contact-us" element={<ContactUsPage />} />
              <Route path="/how-it-works" element={<HowItWorks />} />
              <Route path="/trainings" element={<TrainingsPage />} />
              <Route path="/training-details/:slug" element={<TrainingDetailsPage />}/>
              <Route path="/schedule-details/:slug" element={<ScheduleDetailsPage />}/>
              <Route path="/counselling" element={<CounsellingPage />} />
              <Route path="/forgot-password" element={<ForgotPasswordPage />} />
              <Route path="/reset-password" element={<ResetPasswordPage />} />
              <Route path="/resource-outsourcing" element={<ResourceOutSourcing />} />
              <Route path="/investors-club" element={<InvestorsClub />} />
              <Route path="/terms-and-conditions" element={<TermsAndConditions />}/>
              <Route path="/admin/sign-in" element={<AdminSignIn />} />
              <Route path="/campus-ambassadors" element={<CampusAmbassadors />} />
              
            </Route>

            {/* =========== Admin Dashboard Routes =========== */}

            <Route element={<AdminLayout />}>
              <Route
                path="/admin/dashboard"
                element={
                  <AdminRoute userType={userCategory}>
                    <AdminDashPage />
                  </AdminRoute>
                }
              />
             

              <Route
                path="/admin/careers-detail-info/shortlisted-candidates/:jobID"
                element={
                  <AdminRoute userType={userCategory}>
                    <AdminShortlistedCandidates />
                  </AdminRoute>
                }
              />

              <Route
                path="/admin/candidate-profile/:candidateID"
                element={
                  <AdminRoute userType={userCategory}>
                    <AdminShortlistedCandidateProfile />
                  </AdminRoute>
                }
              />

              <Route
                path="/admin/active-companies"
                element={
                  <AdminRoute userType={userCategory}>
                    <AdminActiveCompanies />
                  </AdminRoute>
                }
              />
              <Route
                path="/admin/suspended-companies"
                element={
                  <AdminRoute userType={userCategory}>
                    <SuspendedCompanies />
                  </AdminRoute>
                }
              />
              <Route
                path="/admin/signup-requests"
                element={
                  <AdminRoute userType={userCategory}>
                    <SignUpRequests />
                  </AdminRoute>
                }
              />

              <Route
                path="/admin/list-of-skills"
                element={
                  <AdminRoute userType={userCategory}>
                    <AdminListOfSkills />
                  </AdminRoute>
                }
              />
              <Route
                path="/admin/add-new-skill"
                element={
                  <AdminRoute userType={userCategory}>
                    <AddNewSkill />
                  </AdminRoute>
                }
              />
              <Route
                path="/admin/approve-skills"
                element={
                  <AdminRoute userType={userCategory}>
                    <ApproveSkills />
                  </AdminRoute>
                }
              />

              <Route
                path="/admin/counselling-requests"
                element={
                  <AdminRoute userType={userCategory}>
                    <CounsellingRequestsPage />
                  </AdminRoute>
                }
              />
              <Route
                path="/admin/counsellors"
                element={
                  <AdminRoute userType={userCategory}>
                    <CounsellorsPage />
                  </AdminRoute>
                }
              />
              <Route
                path="/admin/add-new-training"
                element={
                  <AdminRoute userType={userCategory}>
                    <AddNewTrainingPage />
                  </AdminRoute>
                }
              />
              <Route
                path="/admin/list-of-trainings"
                element={
                  <AdminRoute userType={userCategory}>
                    <ListOfTrainingsPage />
                  </AdminRoute>
                }
              />

              <Route
                path="/admin/update-training/:trainingID"
                element={
                  <AdminRoute userType={userCategory}>
                    <UpdateTrainingPage />
                  </AdminRoute>
                }
              />
              <Route
                path="/admin/training-details/:trainingID"
                element={
                  <AdminRoute userType={userCategory}>
                    <TrainingAdminDetail />
                  </AdminRoute>
                }
              />
              <Route
                path="/admin/add-schedule"
                element={
                  <AdminRoute userType={userCategory}>
                    <AddAdminSchedule />
                  </AdminRoute>
                }
              />
              <Route
                path="/admin/list-of-schedules"
                element={
                  <AdminRoute userType={userCategory}>
                    <ViewAdminSchedules />
                  </AdminRoute>
                }
              />
              <Route
                path="/admin/schedule/:scheduleID"
                element={
                  <AdminRoute userType={userCategory}>
                    <EditAdminSchedule />
                  </AdminRoute>
                }
              />
              <Route
                path="/admin/schedule-details/:scheduleID"
                element={
                  <AdminRoute userType={userCategory}>
                    <ScheduleDetailAdmin />
                  </AdminRoute>
                }
              />
              <Route
                path="/admin/schedule/enrollments/:scheduleID"
                element={
                  <AdminRoute userType={userCategory}>
                    <ViewAdminEnrollments />
                  </AdminRoute>
                }
              />
              <Route
                path="/admin/training-requests/:trainingID"
                element={
                  <AdminRoute userType={userCategory}>
                    <ViewTrainingRequests />
                  </AdminRoute>
                }
              />

              <Route
                path="/admin/received-trainings-requests"
                element={
                  <AdminRoute userType={userCategory}>
                    <TrainingsRequestsPage />
                  </AdminRoute>
                }
              />

              <Route
                path="/admin/trainers"
                element={
                  <AdminRoute userType={userCategory}>
                    <TrainersPage />
                  </AdminRoute>
                }
              />
              <Route
                path="/admin/trainer-details/:trainerID"
                element={
                  <AdminRoute userType={userCategory}>
                    <TrainerDetailsPage />
                  </AdminRoute>
                }
              />
              <Route
                path="/admin/trainer-trainings"
                element={
                  <AdminRoute userType={userCategory}>
                    <TrainerTrainingsPage />
                  </AdminRoute>
                }
              />
              <Route
                path="/admin/trainer-schedules"
                element={
                  <AdminRoute userType={userCategory}>
                    <TrainerSchedulesPage />
                  </AdminRoute>
                }
              />
              <Route
                path="/admin/list-of-candidates"
                element={
                  <AdminRoute userType={userCategory}>
                    <ViewCandidatesPage />
                  </AdminRoute>
                }
              />
              <Route
                path="/admin/candidate-details/:candidateID"
                element={
                  <AdminRoute userType={userCategory}>
                    <CandidateDetailsPage />
                  </AdminRoute>
                }
              />
              <Route
                path="/admin/company/:companyID"
                element={
                  <AdminRoute userType={userCategory}>
                    <CompanyJobsView />
                  </AdminRoute>
                }
              />
              
              <Route
                path="/admin/campus-ambassadors"
                element={
                  <AdminRoute userType={userCategory}>
                    <CampusAmbassadorsAdminPage />
                  </AdminRoute>
                }
              />
            </Route>

            {/* =========== Candidate Dashboard Routes =========== */}

            <Route
              element={
                <CandidateLayout
                  unreadMessages={unreadMessages}
                  isSuccess={isUnreadMessagesSuccess}
                  footer={false}
                />
              }
            >
              <Route
                path="/candidate/dashboard"
                exact
                element={
                  <CandidateRoute userType={userCategory}>
                    <CandidateDashPage />
                  </CandidateRoute>
                }
              />
              <Route
                path="/candidate/my-jobs"
                exact
                element={
                  <CandidateRoute userType={userCategory}>
                    <MyJobs />
                  </CandidateRoute>
                }
              />
              <Route
                path="/candidate/view-download-cv"
                exact
                element={
                  <CandidateRoute userType={userCategory}>
                    <ViewDownloadCV />
                  </CandidateRoute>
                }
              />
              <Route
                path="/candidate/job-preferences"
                exact
                element={
                  <CandidateRoute userType={userCategory}>
                    <JobPreferences />
                  </CandidateRoute>
                }
              />
              <Route
                path="/candidate/profile"
                exact
                element={
                  <CandidateRoute userType={userCategory}>
                    <CandidateProfile />
                  </CandidateRoute>
                }
              />
              <Route
                path="/candidate/campus-ambassador"
                exact
                element={
                  <CandidateRoute userType={userCategory}>
                    <CampusAmbassadorProfile />
                  </CandidateRoute>
                }
              />
              <Route
                path="/candidate/chat"
                exact
                element={
                  <CandidateRoute userType={userCategory}>
                    <Chat
                      unreadMessages={unreadMessages}
                      setUnreadMessages={setUnreadMessages}
                    />
                  </CandidateRoute>
                }
              />
              <Route
                path="/candidate/my-enrollments"
                exact
                element={
                  <CandidateRoute userType={userCategory}>
                    <MyTrainings />
                  </CandidateRoute>
                }
              />
              <Route
                path="/candidate/company-details/:companySlug"
                exact
                element={
                  <CandidateRoute userType={userCategory}>
                    <CompanyDetailsPage />
                  </CandidateRoute>
                }
              />
              <Route
                path="/candidate/favorites"
                exact
                element={
                  <CandidateRoute userType={userCategory}>
                    <ViewFavorites />
                  </CandidateRoute>
                }
              />
              <Route component={NotFoundPage} />{" "}
              {/* This catches all other routes */}
            </Route>

            <Route element={<PublicLayout />}>
              <Route
                path="/profile-completion"
                exact
                element={
                  <CandidateRoute userType={userCategory}>
                    <ProfileCompletion />
                  </CandidateRoute>
                }
              />
              <Route
                path="/trainer/candidate-details/:candidateID"
                element={
                  <AdminRoute userType={userCategory}>
                    <CandidateDetailsPage />
                  </AdminRoute>
                }
              />
            </Route>

            {/* =========== Company Dashboard Routes =========== */}

            <Route
              element={
                <CompanyLayout
                  unreadMessages={unreadMessages}
                  isSuccess={isUnreadMessagesSuccess}
                />
              }
            >
              <Route
                path="/company/dashboard"
                exact
                element={
                  <CompanyRoute userType={userCategory}>
                    <CompanyDashPage />
                  </CompanyRoute>
                }
              />

              <Route
                path="/company/search-resources"
                element={
                  <CompanyRoute userType={userCategory}>
                    <SearchResourcesPage />
                  </CompanyRoute>
                }
              />
              <Route
                path="/company/view-resources"
                element={
                  <CompanyRoute userType={userCategory}>
                    <ViewResources />
                  </CompanyRoute>
                }
              />
              <Route
                path="/company/resource/:resourceID"
                element={
                  <CompanyRoute userType={userCategory}>
                    <ResourceDetailPage />
                  </CompanyRoute>
                }
              />
              <Route
                path="/company/add-new-resource"
                element={
                  <CompanyRoute userType={userCategory}>
                    <AddNewResource />
                  </CompanyRoute>
                }
              />
              <Route
                path="/company/add-new-resource/:resourceID"
                element={
                  <CompanyRoute userType={userCategory}>
                    <AddNewResource />
                  </CompanyRoute>
                }
              />

              <Route
                path="/company/request-new-skill"
                element={
                  <CompanyRoute userType={userCategory}>
                    <RequestNewSkill />
                  </CompanyRoute>
                }
              />
              <Route
                path="/company/list-of-skills"
                element={
                  <CompanyRoute userType={userCategory}>
                    <ListOfSkills />
                  </CompanyRoute>
                }
              />

              <Route
                path="/company/list-of-companies"
                element={
                  <CompanyRoute userType={userCategory}>
                    <ListOfCompanies />
                  </CompanyRoute>
                }
              />

              <Route
                path="/company/profile"
                element={
                  <CompanyRoute userType={userCategory}>
                    <CompanyProfile />
                  </CompanyRoute>
                }
              />

              <Route
                path="/company/outsourcing-requests"
                element={
                  <CompanyRoute userType={userCategory}>
                    <OutsourcingRequests />
                  </CompanyRoute>
                }
              />

              <Route
                path="/company/post-job"
                element={
                  <CompanyRoute userType={userCategory}>
                    <PostJob />
                  </CompanyRoute>
                }
              />

              <Route
                path="/company/post-job/:jobID"
                element={
                  <CompanyRoute userType={userCategory}>
                    <PostJob />
                  </CompanyRoute>
                }
              />

              <Route
                path="/company/jobs"
                element={
                  <CompanyRoute userType={userCategory}>
                    <ViewJobs />
                  </CompanyRoute>
                }
              />

              <Route
                path="/company/job/:jobID"
                element={
                  <CompanyRoute userType={userCategory}>
                    <JobDetail />
                  </CompanyRoute>
                }
              />

              <Route
                path="/company/job/shortlisted-candidates/:jobID"
                element={
                  <CompanyRoute userType={userCategory}>
                    <ShortlistedCandidates />
                  </CompanyRoute>
                }
              />
              <Route
                path="/company/chat"
                element={
                  <CompanyRoute userType={userCategory}>
                    <Chat />
                  </CompanyRoute>
                }
              />

              <Route
                path="/company/:companySlug"
                element={
                  <CompanyRoute userType={userCategory}>
                    <CompanyDetail />
                  </CompanyRoute>
                }
              />

              <Route
                path="/company/candidate-profile/:candidateID"
                element={
                  <CompanyRoute userType={userCategory}>
                    <ShortlistedCandidateProfile />
                  </CompanyRoute>
                }
              />
              <Route
                path="/company/notifications"
                element={
                  <CompanyRoute userType={userCategory}>
                    <NotificationsPage />
                  </CompanyRoute>
                }
              />
            </Route>

            {/* =========== Trainer Dashboard Routes ===========  */}
            <Route
              element={
                <TrainerLayout
                  unreadMessages={unreadMessages}
                  isSuccess={isUnreadMessagesSuccess}
                />
              }
            >
              <Route
                path="/trainer/dashboard"
                exact
                element={
                  <TrainerRoute userType={userCategory}>
                    <TrainerDashPage />
                  </TrainerRoute>
                }
              />
              <Route
                path="/trainer/add-training"
                element={
                  <TrainerRoute userType={userCategory}>
                    <AddTraining />
                  </TrainerRoute>
                }
              />

              <Route
                path="/trainer/trainings"
                element={
                  <TrainerRoute userType={userCategory}>
                    <ViewTrainings />
                  </TrainerRoute>
                }
              />

              <Route
                path="/trainer/training/:trainingID"
                element={
                  <TrainerRoute userType={userCategory}>
                    <EditTraining />
                  </TrainerRoute>
                }
              />
              <Route
                path="/trainer/training-details/:trainingID"
                element={
                  <TrainerRoute userType={userCategory}>
                    <TrainingDetail />
                  </TrainerRoute>
                }
              />

              <Route
                path="/trainer/add-schedule"
                element={
                  <TrainerRoute userType={userCategory}>
                    <AddSchedule />
                  </TrainerRoute>
                }
              />

              <Route
                path="/trainer/schedules"
                element={
                  <TrainerRoute userType={userCategory}>
                    <ViewSchedules />
                  </TrainerRoute>
                }
              />

              <Route
                path="/trainer/schedule/:scheduleID"
                element={
                  <TrainerRoute userType={userCategory}>
                    <EditSchedule />
                  </TrainerRoute>
                }
              />
              <Route
                path="/trainer/schedule-details/:scheduleID"
                element={
                  <TrainerRoute userType={userCategory}>
                    <ScheduleDetail />
                  </TrainerRoute>
                }
              />
              <Route
                path="/trainer/schedule/enrollments/:scheduleID"
                element={
                  <TrainerRoute userType={userCategory}>
                    <ViewEnrollments />
                  </TrainerRoute>
                }
              />
              <Route
                path="/trainer/schedule/enrollments/:scheduleID"
                element={
                  <TrainerRoute userType={userCategory}>
                    <ViewEnrollments />
                  </TrainerRoute>
                }
              />
              <Route
                path="/trainer/training-requests/:trainingID"
                element={
                  <TrainerRoute userType={userCategory}>
                    <ViewRequests />
                  </TrainerRoute>
                }
              />

              <Route
                path="/trainer/profile"
                element={
                  <TrainerRoute userType={userCategory}>
                    <TrainerProfile />
                  </TrainerRoute>
                }
              />
            </Route>

            {/* =========== Counsellor Dashboard Routes ===========  */}
            <Route
              element={
                <TrainerLayout
                  unreadMessages={unreadMessages}
                  isSuccess={isUnreadMessagesSuccess}
                />
              }
            >
              {/* <Route
                path="/trainer/dashboard"
                exact
                element={
                  <TrainerRoute userType={userCategory}>
                    <TrainerDashPage />
                  </TrainerRoute>
                }
              />
              <Route
                path="/trainer/add-training"
                element={
                  <TrainerRoute userType={userCategory}>
                    <AddTraining />
                  </TrainerRoute>
                }
              /> */}

              {/* <Route
                path="/trainer/add-training/:trainingID"
                element={
                  <TrainerRoute userType={userCategory}>
                    <PostJob />
                  </TrainerRoute>
                }
              /> */}

              {/* <Route
                path="/trainer/trainings"
                element={
                  <TrainerRoute userType={userCategory}>
                    <ViewTrainings />
                  </TrainerRoute>
                }
              />

              <Route
                path="/trainer/training/:trainingID"
                element={
                  <TrainerRoute userType={userCategory}>
                    <TrainingDetail />
                  </TrainerRoute>
                }
              />

              <Route
                path="/trainer/add-schedule"
                element={
                  <TrainerRoute userType={userCategory}>
                    <AddSchedule />
                  </TrainerRoute>
                }
              />

              <Route
                path="/trainer/schedules"
                element={
                  <TrainerRoute userType={userCategory}>
                    <ViewSchedules />
                  </TrainerRoute>
                }
              />

              <Route
                path="/trainer/schedule/:scheduleID"
                element={
                  <TrainerRoute userType={userCategory}>
                    <ScheduleDetail />
                  </TrainerRoute>
                }
              />

              <Route
                path="/trainer/profile"
                element={
                  <TrainerRoute userType={userCategory}>
                    <TrainerProfile />
                  </TrainerRoute>
                }
              /> */}
            </Route>
          </Routes>
          </Suspense>
        </ScrollToTop>
      </div>
    </Router>
  );
}

export default App;
