let IP;

if (process.env.NODE_ENV === "production") {
  IP = "searchopal-dev-11311db4105f.herokuapp.com";
} else {
  // IP = "192.168.100.48:8 000";
  // IP = "192.168.100.4:8000";
  IP = "localhost:8000";
}

export default IP;
